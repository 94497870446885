const defaultUrl = '' // Change this to your API URL

let localSettingUrl = '' //when empty, it will use settings in web.config

//to use local setting remove // below...
localSettingUrl = '/'

export const GetContentConfigFile = async ({ url = '', fileName = '' }: any) => {
    let path = `${defaultUrl}${url}/${fileName}`

    localSettingUrl && (path = localSettingUrl + fileName)

    //co-pilot: I get an cors error when trying to fetch a file from a server in react. How do I fix this?
    // answer:
    // Here's an example of how you can set these headers in a iis server (web.config) to allow CORS requests:
    // <system.webServer>
    //     <httpProtocol>
    //         <customHeaders>
    //             <add name="Access-Control-Allow-Origin" value="*" />
    //             <add name="Access-Control-Allow-Methods" value="GET, POST, PUT, DELETE" />
    //             <add name="Access-Control-Allow-Headers" value="Content-Type" />
    //         </customHeaders>
    //     </httpProtocol>
    // </system.webServer>

    const response = await fetch(path)
    //const response = await fetch('/content.etjanstMall.languageSupport.json')

    const data = await response.json()

    if (!response.ok) {
        return { data: 'error', status: response.status }
    } else {
        return { data: data }
    }
}

export const GetQuestionsConfigFile = async ({ url = '', fileName = '' }: any) => {
    let path = `${defaultUrl}${url}/${fileName}`

    localSettingUrl && (path = localSettingUrl + fileName)

    const response = await fetch(path)
    //const response = await fetch('/content.etjanstMall.questions.json')

    const data = await response.json()

    if (!response.ok) {
        return { data: 'error', status: response.status }
    } else {
        return { data: data }
    }
}
