import { Link, createBrowserRouter } from 'react-router-dom'
import RootLayout from '../../pages/root/Root'
import ErrorPage from '../../pages/root/Error'

import BackOffice from '../../pages/backOffice/List'
import Details from '../../pages/backOffice/Details'
import Release1 from '../../pages/release1'
import Release2 from '../../pages/release2'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        errorElement: <ErrorPage />,

        children: [{ index: true, element: <Release1 /> }],
    },

    // {
    //     path: '/release1',
    //     element: <RootLayout />,
    //     errorElement: <ErrorPage />,
    //     children: [{ index: true, element: <Release1 /> }],
    // },

    // {
    //     path: '/release2',
    //     element: <RootLayout />,
    //     errorElement: <ErrorPage />,
    //     children: [{ index: true, element: <Release2 /> }],
    // },

    // {
    //     path: '/backoffice',
    //     element: <RootLayout />,
    //     errorElement: <ErrorPage />,
    //     handle: {
    //         crumb: () => <Link to="/">Startsidan</Link>,
    //     },
    //     children: [
    //         { index: true, element: <BackOffice /> },
    //         {
    //             path: 'details',
    //             element: <Details />,
    //             handle: {
    //                 crumb: () => <Link to="Step1">Steg 1</Link>,
    //             },
    //         },
    //     ],
    // },

    // {
    //     path: '/DSA',
    //     element: <RootLayout />,
    //     errorElement: <ErrorPage />,

    //     children: [{ index: true, element: <DSA /> }],
    // },
])
