import { useEffect, useState } from 'react'
/*global state management with redux*/
import { useAppSelector, useAppDispatch } from '../../app/hooks'

import {
    contentConfigFileToGlobalStateAsync,
    selectActivatedLanguage,
    selectLanguageSupportOverAll,
    selectStatusLanguageSupportContent,
    selectLanguageSupportState,
} from '../languageSupport/languageSupport.Slice'

import { useOutletContext } from 'react-router-dom'

import MapSupport from './MapSupport'

const InputComponentLibrary = () => {
    const { handleModal, handleFullScreen }: any = useOutletContext()

    const dispatch = useAppDispatch()
    const activatedLanguage: string = useAppSelector(selectActivatedLanguage)

    const languageSupported: any = useAppSelector(selectLanguageSupportOverAll)
    const statusLanguageSupportedContent = useAppSelector(selectStatusLanguageSupportContent)
    const languageSupportLoaded = useAppSelector(selectLanguageSupportState).languageSupportLoaded

    const [config, setConfig] = useState({})

    //1. Get config from json file
    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
    }, [])

    return (
        <>
            <MapSupport languageSupported={languageSupported} handleFullScreen={handleFullScreen} />
        </>
    )
}

export default InputComponentLibrary
