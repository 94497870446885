import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import languageReducer from '../features/languageSupport/languageSupport.Slice'

import ExampleReducer from '../features/Release2/questions.Slice'

export const store = configureStore({
    reducer: {
        Example: ExampleReducer,

        languageSupport: languageReducer,
    },
    // middleware: getDefaultMiddleware =>
    //     getDefaultMiddleware({
    //         serializableCheck: false,
    //     }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
