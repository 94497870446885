import { ILanguageSupport } from './languageSupport.Types'

export const LanguageSupport: ILanguageSupport = {
    activatedLanguage: 'none', //Default language
    languageSupportLoaded: false,
    supportedLanguage: [
        {
            language: 'none',
            supportedOverall: {
                ForwardButton: '',
                BackButton: '',
                StartButton: '',
                SendButton: '',
                UploadButton: '',
                SelectListDefault: '',
                MainHeadLine: '',
            },

            NameOfService: '',
            Headline: '',
            Body: '',

            LinksForMoreInfo: [
                {
                    title: '',
                    url: '',
                },
            ],
            supportedTextBlock: [{}],

            supportedPage: [
                {
                    page: '',
                    headline: '',
                    body: '',
                },
            ],
        },
    ],
    status: 'idle',
}
