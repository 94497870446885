/*
Description:
This file contains the API handler functions for the application.
The functions are used to interact with the API and get the data from the server.
First function is GetTokenHandler, which is used to get the token from the server.
Second function is SubmitHandler, which is used to send the data to the server with token.
GetHandler is used to get the data from the server. Mainly used for testing purpose.
See config.json for the API URL settings.
To use the local setting (https://localhost:{port}/api), just uncomment localSettingUrl.
*/

const defaultUrl = 'https://utv-api-etjanst.pts.ad/api' // Change this to your API URL
//const defaultUrl = 'https://localhost:7298/api'

let localSettingUrl = ''
//to use local setting...
//localSettingUrl = 'https://localhost:7298/api'

let defaultApiController = ''
const defaultAccessHeader = '*'
const nameOfService = 'E-tjanstMall'

export const SubmitHandler = async ({
    data = {},
    confirmed,
    token = '',
    postToServerUrl = defaultUrl,
    AccessHeader = defaultAccessHeader,
}: //apiController = defaultApiController,
any) => {
    if (!token) {
        alert('no token: ' + token)
        return { data: 'no token' }
    }
    //alert('SubmitHandler')

    localSettingUrl && (postToServerUrl = localSettingUrl + '/FrontEndSavedDataJsons')
    const jsonString = { jsonData: JSON.stringify(data), nameOfService, reviewedAndConfirmed: confirmed }

    // console.log('data', data)
    // console.log('token', token)
    //console.log('jsonString', JSON.stringify(jsonString))

    const response = await fetch(`${postToServerUrl}`, {
        method: 'POST',
        body: JSON.stringify(jsonString),
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Token': token,
            AccessHeader: AccessHeader,
        },
    })
    const result = await response.json()
    if (!response.ok) {
        alert('Error')
        return { data: 'error' }
    } else {
        //alert('Success')
        return { data: 'success', useName: result.userName, userId: result.userId, appId: result.appDataId }
    }
}

export const EditHandler = async ({
    data = {},
    confirmed,
    token = '',
    postToServerUrl = defaultUrl,
    AccessHeader = defaultAccessHeader,
    appId = 0,
}: //apiController = defaultApiController,
any) => {
    if (!token) {
        alert('no token: ' + token)
        return { data: 'no token' }
    }
    //alert('EditHandler')
    localSettingUrl && (postToServerUrl = localSettingUrl + '/FrontEndSavedDataJsons' + '/' + appId)
    const jsonString = { jsonData: JSON.stringify(data), nameOfService, reviewedAndConfirmed: confirmed, id: appId }

    // console.log('data', data)
    // console.log('token', token)
    //console.log('jsonString', JSON.stringify(jsonString))

    const response = await fetch(`${postToServerUrl}`, {
        method: 'PUT',
        body: JSON.stringify(jsonString),
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Token': token,
            AccessHeader: AccessHeader,
        },
    })
    //console.log('response', response)
    if (!response.ok) {
        //alert('Error: ' + response.statusText + ' ' + response.status)
        return { data: 'error' }
    } else {
        //alert('Succes: ' + response.status)
        return { data: 'success' }
    }
}

export const GetTokenHandler = async ({ AccessHeader = defaultAccessHeader, getTokenUrl = '' }) => {
    localSettingUrl && (getTokenUrl = localSettingUrl + '/Token/GetToken')

    const requestOptions = {
        method: 'Get',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            AccessHeader: AccessHeader,
        },
    }

    const response = await fetch(`${getTokenUrl}`, requestOptions)
        .then(resp => {
            return { result: resp.text() }
            //return { result: 'error' }
        })
        .catch(error => {
            return { result: 'error', status: error }
        })

    return response

    //const text2 = await response.text()

    /*
    console.log('token_response', text2)
    if (!response.ok) {
        //alert('Error: ' + response.statusText + ' ' + response.status)
        return { data: 'error' }
    } else {
        //alert('Succes: ' + response.status)
        //return { data: 'success' }
        return text2
    }
    */
}

//this is used during test, for "/backOffice"

export async function GetHandler({ AccessHeader = defaultAccessHeader, apiController = defaultApiController }) {
    let url = defaultUrl

    localSettingUrl && (url = localSettingUrl)

    console.log('apiController', apiController)
    console.log('url', url)

    const response = await fetch(`${url}/${apiController}`, {
        method: 'GET',

        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            AccessHeader: AccessHeader,
        },
    })
    const data = await response.json()
    console.log('data', data)
    const dataNameOfService = data && data.filter((item: any) => item.nameOfService === nameOfService)
    return dataNameOfService
}

// export const GetCurrentDateTime = async ({ AccessHeader = defaultAccessHeader, url = defaultUrl }) => {
//     const requestOptions = {
//         method: 'Get',
//         headers: { 'Content-Type': 'application/json', AccessHeader: AccessHeader },
//     }

//     const response = await fetch(`${url}/${defaultApiController}/CurrentDateTime`, requestOptions)
//     const text = await response.text()
//     console.log('CurrentDateTime response', text)
//     return text
// }

// export async function GetHandler(apiController = defaultApiController) {
//     const url = defaultUrl

//     const response = await fetch(`${url}/${apiController}`, {
//         method: 'GET',

//         headers: {
//             'Content-Type': 'application/json',
//         },
//     })
//     const data = await response.json()
//     console.log('data', data)
//     return data
// }
