import FeatureGisMaps from '../../features/Release1'
import { TransitionForPages } from 'ui-components-react-package-45ds546dksw34'

const gisMaps = () => {
    return (
        <>
            <TransitionForPages>
                <FeatureGisMaps />
            </TransitionForPages>
        </>
    )
}

export default gisMaps
