import { useEffect, useState } from 'react'
import { CookieBanner, MapHeadlineAndBody, TransitionForPages } from 'ui-components-react-package-45ds546dksw34'

const MapSupport = ({ languageSupported = {}, handleFullScreen }: any) => {
    const StartWithFullScreen = true

    const [config, setConfig] = useState({})
    const [cookieTouched, setCookieTouched] = useState(false)
    //Get config from json file
    useEffect(() => {
        //Disable auto scrolling from Hajk map for 2 seconds
        window.onscroll = function () {
            window.scrollTo(0, 0)
        }
        setTimeout(() => {
            window.onscroll = function () {}
        }, 2000)

        fetch('/content.eTjanstBredbandskartan.maps.json')
            //fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
    }, [])

    const { geoMaps }: any = config
    //config && console.log('MapSupport data:', JSON.stringify(config))
    return (
        <>
            {/* <fieldset> */}
            {config && geoMaps && (
                <>
                    {/* {!cookieTouched && (
                        <>
                            <CookieBanner
                                isTouched={() => setCookieTouched(true)}
                                visible={true}
                                headline="Kakor (cookies)"
                                infoText="PTS skulle vilja använda en statistikkaka i syfte att rapportera användarstatistik till Europeiska kommissionen. Samtycker du till det? Du kan när som helst ändra ditt val. För att återkalla ditt samtycke klickar du på ”Kakor” längst ned till vänster på webbplatsen."
                                consentLabel="Ja, jag samtycker"
                                notConsentLabel="Nej, jag samtycker inte"
                                linkUrl="https://www.pts.se"
                                linkText="Kakor (cookies) på pts.se"
                            />
                        </>
                    )} */}

                    <MapHeadlineAndBody
                        info={languageSupported}
                        geoMaps={geoMaps}
                        showBody={true}
                        showLinksMoreInfo={false}
                        handleFullScreen={handleFullScreen}
                        startWithFullScreen={StartWithFullScreen}
                    />
                </>
            )}
            {/* </fieldset> */}
        </>
    )
}

export default MapSupport
