import React, { FC } from 'react'
import { MainProps } from './Main.types'
import './Main.css'
import { Col, Container, Row } from 'react-bootstrap'

const Header: FC<MainProps> = ({ fullScreen = false, children, ...props }) => {
    return (
        <Container fluid="sm" style={fullScreen ? { maxWidth: '100%' } : { maxWidth: '860px' }} {...props}>
            <Row className="containerRow">
                <Col>
                    <main>{children}</main>
                </Col>
            </Row>
        </Container>
    )
}

export default Header
