export const ExampleConfig = [
    // ----------Step 1-----------
    {
        Id: 1,
        Order: 1,
        Step: 1,
        Category: 'Komponenter enklare',
        CategoryOrder: 1,
        Question: '',
        Answer: '',
        PlaceholderText: '',
        QuestionType: 'Checkbox',
        isTouched: false,
        error: false,
        errorType: ['notEmpty'],
        errorSpecificMessenge: 'Du måste skriva något',
        languageSupport: [
            {
                language: 'swe',
                Question: 'Checkbox ifrån komponentbiblioteket',

                errorSpecificMessenge: 'Ange Name',
            },
            {
                language: 'eng',
                Question: 'Checkbox from the component library',

                errorSpecificMessenge: 'Enter the name',
            },
        ],
    },
]
